<template>
  <div>
    <!-- Error while fetching -->
    <div v-if="error">
      {{ error }}
    </div>

    <!-- Success -->
    <template v-else>
      <span class="global-placeholder-el-small"></span>
      <HeroTextBacklink
        v-if="previousWhlPageType === WhlPageType.OVERVIEW"
        :content="null"
        :config="{ linkTo: '/' }"
        class="global-grid"
      />
      <ListStandardList
        :total-records="totalRecords"
        :content="listContent"
        :allowed-list-types="allowedListTypes"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { List } from '@components/List/Standard/models';
import type { List as TeaserList } from '@components/Teaser/List/models';
import type { RawEventListItemFragment } from '@gql/fragments/__generated/RawEventListItem';
import type { RawWidgetConfigEventDefFragment } from '@gql/fragments/__generated/RawWidgetConfigEventDef';
import { WhlModuleType } from '@models/WhlModuleType';
import { WhlPageType } from '@models/WhlPageType';
import { ListType } from '@stores/globalStore';
const previousWhlPageTypes = useSessionStorage('previousWhlPageTypes', [])
  .value as WhlPageType[];
const previousWhlPageType = Object.values(WhlPageType).find(
  (type) => type === previousWhlPageTypes[previousWhlPageTypes.length - 1]
);

definePageMeta({
  whlModuleType: WhlModuleType.EVENT,
  whlPageType: WhlPageType.LIST,
  middleware: ['whl-module-type-tracker', 'whl-page-type-tracker'],
});

const { t, d } = useI18n();

const widgetConfig = await useWidgetConfig();
const instanceConfig = useWhlInstanceConfig();

ensureWhlModuleIsEnabled(widgetConfig, WhlModuleType.EVENT);
const widgetTypeConfig: Ref<RawWidgetConfigEventDefFragment> =
  useWidgetTypeConfig(
    widgetConfig
  ) as ComputedRef<RawWidgetConfigEventDefFragment>;

const searchStore = useSearchStore();
const globalStore = useGlobalStore();
globalStore.setListType(toValue(widgetTypeConfig));

useWhlSeoMeta({
  title: t('event.search.title'),
});

searchStore.readStateFromRoute();

const pageSize = computed(() => globalStore.state.pageSize);

const baseFilter = buildEventBaseFilter(widgetConfig);
const { eventUserFilter: userFilter, fulltextSearchQuery } =
  storeToRefs(searchStore);

// make use of fetch composables, where refs can be passed (and changes to the ref´s values will trigger a new fetch)
const {
  error,
  totalRecords,
  events: items,
} = await fetchEvents(
  widgetConfig,
  userFilter,
  baseFilter,
  fulltextSearchQuery,
  false,
  pageSize,
  (state) => globalStore.setLoadingState(state)
);

const listContent = computed(
  (): List => ({
    showTitle: widgetConfig.value?.showSearchResultHeader ?? true,
    title: t('event.search.results.title', totalRecords.value),
    initialType: globalStore.state.listType || ListType.LIST,
    showMap: widgetTypeConfig.value.showMapInList ?? false,
    items:
      items.value?.map((event: RawEventListItemFragment): TeaserList => {
        const { nextEventDateAndTimeConsideringDate, allEventDatesLinkList } =
          transformEventDateData(event, { t, d }, 'medium');

        const infoTagItems = [];
        if (!isEmpty(nextEventDateAndTimeConsideringDate.value?.date)) {
          infoTagItems.push(nextEventDateAndTimeConsideringDate.value!.date!);
        }
        if (!isEmpty(nextEventDateAndTimeConsideringDate.value?.time)) {
          infoTagItems.push(nextEventDateAndTimeConsideringDate.value!.time!);
        }
        if (!isEmpty(event.contact?.title)) {
          const text = instanceConfig.value.event?.list?.list?.standard
            ?.showContactAddress
            ? joinNonEmptyStrings(
                [event.contact?.title, event.contact?.contact1?.address?.city],
                ', '
              )
            : event.contact?.title;
          infoTagItems.push({
            icon: 'ion:location-sharp',
            text,
          });
        }

        const { toBaseImage: fig } = mapImxPlatformMedia(
          () => (event.image || event.contact?.medium) ?? null
        );

        const categories =
          event.categories?.filter(
            (category) => !isEmpty(category) && isDefined(category.id)
          ) ?? [];

        const customFallbackImagePath = toValue(
          prefixUrlPathWithBaseURL(
            getCustomFallbackImagePath(
              categories,
              instanceConfig.value.event?.customCategoryFallbackIcons ?? {}
            ) || instanceConfig.value.event?.customFallbackIcon
          )
        );

        const category = categories
          ?.filter(
            (category) =>
              isDefined(category) &&
              isDefined(category.parent) &&
              !isEmpty(category.i18nName)
          )
          ?.map((category) => {
            if (
              instanceConfig.value.event?.detail?.hero?.categoryBlacklistRegex?.test(
                category.i18nName!
              )
            ) {
              return category.parent?.i18nName;
            }
            return category.i18nName;
          })
          .filter((categoryName) => !isEmpty(categoryName))
          .at(0);

        return {
          content: {},
          config: {
            id: event.id ?? 0,
            moduleType: event.__typename as WhlModuleType,
            cancelled: event.cancelled,
            coords: {
              latitude: event.geoInfo?.coordinates?.latitude ?? 0,
              longitude: event.geoInfo?.coordinates?.longitude ?? 0,
            },
          },
          fig: {
            content: fig.value,
            config: {
              displayFallbackImage:
                instanceConfig.value.event?.list?.list?.standard
                  ?.displayFallbackImage,
            },
            figFallback: {
              config: {
                customFallbackImagePath: customFallbackImagePath,
              },
            },
          },
          text: {
            content: {},
            config: {
              linkTo: toValue(buildLinkToDetailsPage(event.permaLink)),
            },
            title: {
              config: {
                showCategory:
                  instanceConfig.value.event?.list?.list?.standard
                    ?.showCategory,
              },
              content: {
                main: event.title,
                roof: category,
              },
            },
            infoTags: {
              content: {
                items: infoTagItems,
              },
            },
            datelist: {
              content: allEventDatesLinkList.value,
            },
          },
          buttons: {
            content: null,
            config: {
              id: event.id ?? 0,
              moduleType: event.__typename as WhlModuleType,
            },
            closer: null,
            like: {
              content: null,
              config: {
                isActive: true,
              },
            },
          },
          banner: event.cancelled
            ? {
                content: {
                  text: t('components.teaser.list.banner.text.dyn.cancelled'),
                },
                layout: {
                  variant: 'cancelled',
                },
              }
            : null,
        };
      }) ?? [],
  })
);

const allowedListTypes = computed(() => {
  return widgetTypeConfig.value?.viewTypes?.map((_) => _.id) ?? [];
});

onUpdated(() => {
  window.dispatchEvent(new Event('content-loaded'));
});
</script>
